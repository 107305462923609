<template>
  <v-container
    v-if="caseForm"
    id="viewCaseForm"
    :key="componentKey"
    fluid
  >
    <div
      v-if="loading"
      style="width: 100%; height: 100%;"
    >
      <v-overlay
        absolute
        opacity="0.98"
        :value="loading"
      >
        <v-progress-circular
          :size="50"
          color="white"
          indeterminate
        />
        <div style="position: relative; top: 15px; right: 40%;">
          <img
            src="@/assets/Lavirra_logo_SVG.svg"
            height="28px"
            width="auto"
          >
        </div>
      </v-overlay>
    </div>
    <v-row dense>
      <v-col
        v-if="actualTemplate"
        cols="12"
        sm="2"
      >
        <v-card
          v-if="actualTabTemplateViewCaseModel === 0"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <v-list
            :max-height="($vuetify.breakpoint.height - 81).toString()"
            style=" overflow-y: auto;"
          >
            <v-list-group
              v-if="actualTabTemplateViewCaseModel === 0"
              :value="true"
              dense
              class="mt-n2"
            >
              <v-icon
                slot="prependIcon"
                size="20"
              >
                mdi-at
              </v-icon>
              <template
                v-slot:activator
              >
                <v-list-item-title
                  class="ml-n8"
                  style="font-size: 16px; font-weight: bold;"
                >
                  {{ $t('mixed|suggestions') }}
                </v-list-item-title>
              </template>
              <div
                v-if="actualTemplateAndQuestionairreSuggestions.length"
              >
                <v-list-item
                  v-for="suggestion in actualTemplateAndQuestionairreSuggestions"
                  :key="suggestion.id"
                  three-line
                  dense
                  @click="scrollToSuggestion(suggestion)"
                >
                  <v-list-item-content>
                    <template
                      v-slot:activator
                    >
                      <v-list-item-title>
                        {{ $t('mixed|suggestions') }}
                      </v-list-item-title>
                    </template>
                    <v-list-item-title
                      class="primary--text"
                    >
                      @{{ suggestion.customLabel ? suggestion.label + '[' + suggestion.customLabel + ']' : suggestion.label ? suggestion.label : suggestion.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                      class="mt-1"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ inUse(suggestion) ? 'mdi-checkbox-marked-circle-outline' : icons.notInUseInQuestionairre }}
                      </v-icon>
                      {{ inUse(suggestion) ? $t('mixed|in_use_in_questionnaire') : $t('mixed|not_in_use_in_questionnaire') }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ icons.emptyDatabase }}
                      </v-icon>
                      {{ $t('mixed|extracted_data_available_only_in_case') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div
                v-else
                class="ml-5"
                style="font-size: 14px"
              >
                {{ $t('mixed|no_actual_suggestions') }}
              </div>
            </v-list-group>
          </v-list>
        </v-card>
        <v-card
          v-if="actualTabTemplateViewCaseModel === 1"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <QuestionnaireSuggestionsPanel
            v-if="actualTemplate"
            :template-data="actualTemplate"
            :workbench="currentWorkbench"
            :options="{
              hasClose: false,
              inCase: false,
              inCaseModel: true,
              inTemplate: false,
              isVisibleDocuments: false,
              isVisibleSuggestions: true
            }"
            @addField="addNewQuestionnaireFieldInCaseModel"
            @addAllUnusedFields="addNewFieldsInCaseModelQuestionnaire"
            @deleteField="deleteFieldInCaseModelQuestionnaire"
          />
        </v-card>
      </v-col>

      <v-col
        id="viewCaseFormPrimaryContainer"
        cols="12"
        :sm="actualTemplate ? '8' : '9'"
      >
        <v-card
          :height="($vuetify.breakpoint.height - 72).toString()"
          class="pa-2"
        >
          <v-row
            dense
          >
            <v-col
              cols="12"
              sm="5"
            >
              <v-card
                flat
              >
                <v-card-title>
                  <v-hover
                    v-slot="{ hover }"
                  >
                    <div
                      class="mt-n2"
                      style="min-width: 100%"
                    >
                      <input
                        v-model="currentCaseForm.name"
                        class="pl-2 ml-n2 rounded input"
                        style="white-space:nowrap; text-overflow: ellipsis; min-width: 100%"
                        :style="hover && !flatFields.includes(currentCaseForm.name) ? 'background-color: #eee; text-overflow: initial;' : flatFields.includes(currentCaseForm.name) ? 'background-color: #CFD8DC' : ''"
                        @focus="flatFields.push(currentCaseForm.name)"
                        @blur="changeCaseModelName(currentCaseForm.name); flatFields.splice(0, 1)"
                      >
                    </div>
                  </v-hover>
                </v-card-title>
                <v-card-text>
                  <div>
                    <div class="mt-n2">
                      {{ $t('common|category') }}: {{ translateCategoryIfNeeded(currentCaseForm.category) }}
                      <v-icon
                        small
                        color="accent"
                        class="ml-2"
                        @click="changeCategory(currentCaseForm.category)"
                      >
                        mdi-pencil
                      </v-icon>
                    </div>
                    <div>
                      {{ $t('common|date_created') }}: {{ currentCaseForm.createdAt | date }}
                    </div>
                    <div>
                      {{ $t('common|date_updated') }}: {{ currentCaseForm.updatedAt | dateTime }}
                    </div>
                    <div
                      v-if="currentCaseForm.createdBy && currentCaseForm.createdBy.accountName"
                    >
                      {{ $t('common|created_by') }}: {{ currentCaseForm.createdBy.accountName }}
                    </div>
                    <div
                      v-if="currentCaseForm.createdBy && currentCaseForm.createdBy.companyData"
                    >
                      {{ $t('common|created_by') }}: {{ currentCaseForm.createdBy.companyData.companyName }}
                    </div>
                    <div>
                      <div style="display: flex;">
                        <span class="mt-1">{{ $t('common|description') }}:</span>
                        <v-menu
                          open-on-hover
                          bottom
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-hover
                              v-slot="{ hover }"
                            >
                              <div
                                :id="`modelDescription_${currentCaseForm._id}`"
                                :contenteditable="hover || focus"
                                class="text-truncate grey--text text--darken-2 ml-2 rounded"
                                style="max-width: 380px;"
                                :style="hover && !descriptionFields.includes(currentCaseForm.name) ? `background-color: #eee; border-radius: 10px; padding: 4px; outline: none;` : focus && descriptionFields.includes(currentCaseForm.name) ? `outline: none; border:1px solid #234e79; color: ${$vuetify.theme.themes.light.primary}; background-color: #CFD8DC; border-radius: 10px; padding: 4px;` : `padding: 4px;`"
                                v-bind="attrs"
                                v-on="isEllipsisActive(`modelDescription_${currentCaseForm._id}`) ? on : ''"
                                @focus="fieldFocus(true)"
                                @blur="changeCaseModelDescription()"
                              >
                                {{ caseForm.description }}
                              </div>
                            </v-hover>
                          </template>

                          <v-card
                            v-if="caseForm"
                            max-width="400"
                          >
                            <v-card-text>
                              {{ caseForm.description }}
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-card
                flat
              >
                <v-toolbar
                  dense
                  flat
                  height="35"
                  style="position: sticky; top: 0px; z-index: 1;"
                >
                  <v-subheader
                    class="ml-n7 mt-3"
                  >
                    {{ $t('cases|participants') }}
                  </v-subheader>
                </v-toolbar>
                <v-card-text
                  class="px-2 mt-n3"
                  style="max-height: 170px; overflow-y: auto;"
                >
                  <div
                    v-for="(participant, index) in sharedWithMembers"
                    :key="index"
                    class="mx-auto my-2"
                  >
                    <v-chip
                      outlined
                      small
                    >
                      <v-avatar
                        size="18px"
                        class="ml-n3 mr-1"
                      >
                        <img
                          v-if="participant && participant.avatar"
                          alt="Avatar"
                          :src="`${cfg.baseURL}downloadAvatar/${participant._id}/${participant.avatar.raw.filename}`"
                        >
                        <v-avatar
                          v-else
                        >
                          <v-icon
                            v-if="participant.type === 'account'"
                            color="primary"
                            small
                          >
                            mdi-account
                          </v-icon>
                          <v-icon
                            v-if="participant.type === 'company'"
                            color="primary"
                            small
                          >
                            mdi-domain
                          </v-icon>
                          <v-icon
                            v-if="participant.type === 'group'"
                            color="primary"
                            small
                          >
                            mdi-account-group
                          </v-icon>
                        </v-avatar>
                      </v-avatar>
                      <span
                        class="text-truncate"
                        style="font-size: 12px;"
                      >
                        {{ participant.participantName }}
                      </span>
                    </v-chip>
                  </div>
                </v-card-text>
                <v-toolbar
                  dense
                  flat
                  height="35"
                  style="position: sticky; bottom: 0px; z-index: 1;"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="!account.comapnyId ? on : ''">
                        <v-chip
                          v-if="account.accountType === 'lawyer'"
                          outlined
                          rounded
                          :disabled="disabledRule"
                          color="primary"
                          small
                          class="ml-n2 mt-n2 pr-5"
                          v-bind="attrs"
                          @click.stop.native="shareCaseForm(currentCaseForm)"
                          v-on="on"
                        >
                          <v-icon
                            left
                            small
                          >
                            mdi-plus
                          </v-icon>
                          {{ $t('actions|add_new') }}
                        </v-chip>
                      </div>
                    </template>
                    <span>{{ account.companyId ? $t('cases|share') : $t('expressions|activate_members_plan_to_share') }}</span>
                  </v-tooltip>

                  <v-chip
                    v-if="account.accountType === 'lawyer'"
                    outlined
                    rounded
                    color="primary"
                    small
                    class="ml-2 mt-n2"
                    :disabled="currentCaseForm.sharedWith.accounts.length + currentCaseForm.sharedWith.company.length + currentCaseForm.sharedWith.groups.length === 1"
                    @click="removeCaseForm(currentCaseForm)"
                  >
                    <v-icon
                      left
                      small
                      style="position: absolute; left 6px;"
                    >
                      mdi-minus
                    </v-icon>
                    <span class="ml-3">{{ $t('actions|delete') }}</span>
                  </v-chip>
                </v-toolbar>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-card
                flat
                height="208"
                class="pt-3"
              >
                <v-card-text>
                  <v-row
                    justify="end"
                  >
                    <div v-if="currentCaseForm.pinned.includes(account._id)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            icon
                            small
                            class="mt-n1"
                            v-on="on"
                            @click.stop="removePinFromDashboard(currentCaseForm)"
                          >
                            <v-icon>
                              mdi-pin
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('actions|remove_from_pinboard') }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            icon
                            small
                            class="mt-n1"
                            v-on="on"
                            @click.stop="pinToDashboard(currentCaseForm)"
                          >
                            <v-icon>
                              mdi-pin-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('actions|add_to_pinboard') }}</span>
                      </v-tooltip>
                    </div>
                  </v-row>
                  <v-row
                    dense
                    justify="end"
                    class="mt-4"
                  >
                    <div
                      v-if="account.accountType === 'lawyer'"
                      class="d-flex flex-column"
                    >
                      <v-btn
                        rounded
                        outlined
                        color="primary"
                        x-small
                        min-width="150px"
                        class="mb-2"
                        @click="exportCaseForm(currentCaseForm)"
                      >
                        <v-icon
                          small
                          left
                        >
                          mdi-download
                        </v-icon>
                        {{ $t('actions|export') }}
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        color="primary"
                        x-small
                        min-width="150px"
                        class="mb-2"
                        @click="useCaseForm(currentCaseForm)"
                      >
                        <v-icon
                          small
                          left
                          class="ml-n6"
                        >
                          mdi-briefcase-edit
                        </v-icon>
                        {{ $t('actions|use') }}
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        color="primary"
                        x-small
                        min-width="150px"
                        @click="getCaseFormSharedWithLength(currentCaseForm) > 1 ? removeCaseForm(currentCaseForm) : deleteCaseForm(currentCaseForm)"
                      >
                        <v-icon
                          small
                          left
                        >
                          mdi-trash-can-outline
                        </v-icon>
                        {{ $t('actions|delete_case_model') }}
                      </v-btn>
                      <!-- <v-btn
                        v-if="account.accountType === 'lawyer'"
                        rounded
                        outlined
                        color="primary"
                        x-small
                        width="100%"
                        max-width="250px"
                        class="mb-1"
                        @click="generalQDialog = true"
                      >
                        <v-icon
                          x-small
                          left
                        >
                          mdi-help-box
                        </v-icon>
                        All Questionnaires
                      </v-btn> -->
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="currentCaseForm"
            class="ma-0"
          >
            <v-card
              flat
              :height="($vuetify.breakpoint.height - 295).toString()"
              width="100%"
            >
              <div class="d-flex align-center pa-2">
                <v-tabs
                  v-model="selectedWbTab"
                  height="35"
                  active-class="white"
                  :slider-color="currentCaseForm.workbenches.length === 1 ? 'white' : 'accent'"
                  next-icon="mdi-arrow-right"
                  prev-icon="mdi-arrow-left"
                  show-arrows
                  grow
                  style="max-width: 90%; overflow: auto;"
                >
                  <v-tab
                    v-for="workbench in currentCaseForm.workbenches"
                    :key="workbench._id"
                    class="tabs-text"
                    :style="currentCaseForm.workbenches.length === 1 ? 'color: black; opacity: 1.0; pointer-events: none;' : ''"
                    :name="workbench.name"
                    :to="`/models/${$route.params._id}/${workbench._id}`"
                  >
                    {{ workbench.name }}
                  </v-tab>
                </v-tabs>

                <!-- Edit Workbenches && Add Workbench Menu -->
                <v-icon
                  v-if="account.accountType === 'lawyer'"
                  color="primary"
                  class="mx-2"
                  @click="addWorkbench"
                >
                  mdi-plus
                </v-icon>
                <v-menu
                  v-if="account.accountType === 'lawyer'"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-dots-horizontal
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in workbenchMenuItems"
                      :key="index"
                      @click="triggerAction(item.action)"
                    >
                      <v-icon
                        small
                      >
                        {{ item.icon }}
                      </v-icon>
                      <v-list-item-title class="ml-2">
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-slide-y-transition
                mode="in-out"
                :hide-on-leave="true"
              >
                <router-view
                  v-if="$route.name === 'WorkbenchViewCaseModels'"
                  v-show="$route.name === 'WorkbenchViewCaseModels'"
                />
              </v-slide-y-transition>
            </v-card>
            <transition
              name="fade"
              mode="out-in"
            >
              <router-view
                v-if="$route.name === 'TemplateViewCaseModels'"
              />
            </transition>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        :sm="actualTemplate ? '2' : '3'"
      >
        <!-- Case model documents panel right -->
        <v-card
          v-if="$route.name === 'WorkbenchViewCaseModels'"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <v-list
            :value="true"
            dense
            :max-height="($vuetify.breakpoint.height - 117).toString()"
            style="overflow-y: auto;"
          >
            <v-list-group
              :value="true"
              prepend-icon="mdi-file-hidden"
            >
              <div />
              <template v-slot:activator>
                <v-list-item-title
                  class="ml-n5"
                >
                  <span style="font-size: 16px; font-weight: bold; white-space: normal;">{{ $t('mixed|case_documents') }}</span><br>
                </v-list-item-title>
              </template>
              <draggable
                :list="currentRequiredCaseModelDocs"
                handle=".handle-icon"
                v-bind="dragOptions"
                class="dragArea list-group"
                @start="drag=true; onDragStart()"
                @end="drag=false; onDragEnd()"
              >
                <div
                  v-for="doc in currentRequiredCaseModelDocs"
                  :key="doc.id"
                  class="list-group-item my-2 mx-2"
                >
                  <v-list-item
                    :key="doc.id"
                    class="pa-0"
                    :style="`border-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
                  >
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-card
                          height="100%"
                          color="lightBackground"
                        >
                          <v-list-item-icon>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  class="handle-icon centered-icon"
                                  style="cursor: grab;"
                                  size="30"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.drag }}
                                </v-icon>
                              </template>
                              <span>{{ $t('actions|drag_document_to_workbench') }}</span>
                            </v-tooltip>
                          </v-list-item-icon>
                        </v-card>
                      </v-col>
                      <v-col cols="11">
                        <v-list-item-content
                          class="ml-3"
                        >
                          <div class="d-flex">
                            <v-list-item-icon>
                              <v-icon
                                color="primary"
                              >
                                mdi-file-hidden
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                              class="ml-n7"
                              style="white-space: normal; overflow: hidden; text-overflow: ellipsis;
                              display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;"
                            >
                              {{ translateFileInputField(doc) }}
                            </v-list-item-title>
                            <v-icon
                              small
                              class="mr-2"
                              @click="deleteRequiredDoc(doc)"
                            >
                              mdi-close
                            </v-icon>
                          </div>
                        </v-list-item-content>
                        <!-- <v-list-item-icon>
                          <v-icon
                            @click="deleteRequiredDoc(doc)"
                          >
                            mdi-close
                          </v-icon>
                        </v-list-item-icon> -->
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <!-- <v-divider
                    v-if="index < currentCaseForm.documents.length - 1"
                    :key="index"
                  /> -->
                </div>
              </draggable>
            </v-list-group>
          </v-list>
          <v-btn
            color="primary"
            outlined
            rounded
            x-small
            width="50%"
            class="mt-3"
            style="display: block; margin-left: auto; margin-right: auto"
            @click="documentSuggestionsDialog = true"
          >
            <v-icon
              small
              class="mr-1"
            >
              mdi-plus
            </v-icon>
            {{ $t('actions|add_new') }}
          </v-btn>
        </v-card>

        <!-- Template View right side panel -->
        <v-card
          v-if="$route.name === 'TemplateViewCaseModels' && actualTabTemplateViewCaseModel === 0"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <v-list
            dense
            :max-height="($vuetify.breakpoint.height - 81).toString()"
            style="overflow-y: auto;"
          >
            <v-list-group
              v-if="actualTemplate"
              :value="true"
              class="mt-n2"
            >
              <v-icon
                slot="prependIcon"
                class="mr-n6"
              >
                mdi-file-outline
              </v-icon>
              <template
                v-slot:activator
              >
                <v-list-item-title
                  class="ml-n5"
                >
                  <span style="font-size: 16px; font-weight: bold; white-space: normal;">{{ $t('mixed|workbench_documents') }}</span><br>
                </v-list-item-title>
              </template>
              <div v-if="currentWorkbench.requiredDocuments.length">
                <v-list-item
                  v-for="suggestion in currentWorkbench.requiredDocuments"
                  :key="suggestion.id"
                  three-line
                  dense
                  @click="scrollToQuestionnaireField(suggestion)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text"
                    >
                      {{ translateFileInputField(suggestion) }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ inUse(suggestion) ? 'mdi-checkbox-marked-circle-outline' : icons.notInUseInQuestionairre }}
                      </v-icon>
                      {{ inUse(suggestion) ? $t('mixed|in_use_in_questionnaire') : $t('mixed|not_in_use_in_questionnaire') }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle
                      outlined
                      x-small
                      color="primary"
                    >
                      <v-icon
                        x-small
                        color="primary"
                        class="mr-1 mt-n1"
                      >
                        {{ icons.notUploaded }}
                      </v-icon>
                      {{ $t('clients|not_uploaded_by_client') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div
                v-else
                class="ml-5"
                style="font-size: 14px"
              >
                {{ $t('mixed|no_required_workbench_documents') }}
              </div>
            </v-list-group>
          </v-list>
        </v-card>

        <!-- Questionnaire right side panel -->
        <v-card
          v-if="$route.name === 'TemplateViewCaseModels' && actualTabTemplateViewCaseModel === 1"
          :min-height="($vuetify.breakpoint.height - 72).toString()"
        >
          <QuestionnaireSuggestionsPanel
            v-if="actualTemplate"
            :template-data="actualTemplate"
            :workbench="currentWorkbench"
            :options="{
              hasClose: false,
              inCase: false,
              inCaseModel: true,
              inTemplate: false,
              isVisibleDocuments: true,
              isVisibleSuggestions: false
            }"
            @addField="addNewQuestionnaireFieldInCaseModel"
            @addAllUnusedFields="addNewFieldsInCaseModelQuestionnaire"
            @deleteField="deleteFieldInCaseModelQuestionnaire"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="documentSuggestionsDialog"
      max-width="600"
    >
      <DocumentSuggestions
        :current-case-form="currentCaseForm"
        :reset-document-suggestions-dialog="resetDocumentSuggestionsDialog"
        @close-dialog="documentSuggestionsDialog = false"
      />
    </v-dialog>
    <ChangeCaseModelCategory :current-case-form="currentCaseForm" />
    <EditCaseModelWorkbenches
      ref="EditCaseModelWorkbenches"
      :current-case-form="currentCaseForm"
    />
    <!-- <v-dialog
      v-model="generalQDialog"
      keydown
      max-width="1200px"
      transition="dialog-bottom-transition"
      style="position: relative;"
    >
      <GeneralQuestionnaireForm
        :key="generalQDialog"
        :template-data="allCurrentTemplateData"
        :workbench="currentWorkbench"
        :current-case-form="currentCaseForm"
        :form-id="currentCaseForm._id"
        @close="generalQDialog = false"
      />
    </v-dialog> -->
    <Editor
      v-show="false"
      ref="Editor"
    />
  </v-container>
</template>

<script>
import {
  mdiDrag,
  mdiTrashCanOutline,
  mdiPencil,
  mdiPlus,
  mdiChevronDown,
  mdiChevronUp,
  mdiPinOutline,
  mdiFile,
  mdiClose,
  mdiTextBoxSearchOutline,
  mdiCommentOffOutline,
  mdiDatabaseOffOutline,
  mdiUploadOffOutline
} from '@mdi/js'
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { uniqBy } from '@/utils/utils'
import { saveAs } from 'file-saver'
import cfg from '@/config'
import exportTemplate from '../../pages/TemplateEditor/exportDocx.js'
import generalMixin from '@/utils/generalMixin'
import templateViewCaseModelsMixin from './CaseModelMixins/templateViewCaseModelsMixin'
import draggable from 'vuedraggable'
import JSZip from 'jszip'
import DocumentSuggestions from './DocumentSuggestions.vue'
import Editor from '../TemplateEditor/Editor.vue'
import ChangeCaseModelCategory from '../../components/dialogs/ChangeCaseModelCategory.vue'
import EditCaseModelWorkbenches from './EditCaseModelWorkbenches.vue'
import QuestionnaireSuggestionsPanel from '../../components/forms/QuestionnaireFieldEdit/QuestionnaireSuggestionsPanel.vue'
import caseModelMixin from './CaseModelMixins/caseModelMixin.js'

// import GeneralQuestionairreCardModels from './GeneralQuestionairreCardModels.vue'
// import GeneralQuestionnaireForm from '../../components/forms/GeneralQuestionnaireForm.vue'

export default {
  components: {
    DocumentSuggestions,
    ChangeCaseModelCategory,
    EditCaseModelWorkbenches,
    QuestionnaireSuggestionsPanel,
    Editor,
    draggable

    // GeneralQuestionairreCardModels
    // GeneralQuestionnaireForm
  },
  mixins: [generalMixin, caseModelMixin, templateViewCaseModelsMixin],
  data () {
    return {
      loading: false,
      componentKey: 0,
      cfg,
      selectedCategory: '',
      selectedWbTab: 0,
      actualTabTemplateViewCaseModel: 0,
      caseForm: {},
      documentSuggestionsDialog: false,
      resetDocumentSuggestionsDialog: false,
      icons: {
        trash: mdiTrashCanOutline,
        arrowDown: mdiChevronDown,
        arrowUp: mdiChevronUp,
        pin: mdiPinOutline,
        file: mdiFile,
        close: mdiClose,
        textBox: mdiTextBoxSearchOutline,
        notInUseInQuestionairre: mdiCommentOffOutline,
        emptyDatabase: mdiDatabaseOffOutline,
        notUploaded: mdiUploadOffOutline,
        drag: mdiDrag
      },
      workbenchMenuItems: [
        {
          text: this.$t('actions|edit_workbenches'),
          icon: mdiPencil,
          action: 'editWorkbenchesDialog'
        },
        {
          text: this.$t('actions|add_workbench'),
          icon: mdiPlus,
          action: 'addWorkbench'
        }
      ],
      flatFields: [],
      descriptionFields: [],
      focus: null,
      dragOptions: {
        group: {
          name: 'docs',
          pull: 'clone',
          put: false
        },
        sort: false
      }
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      caseForms: state => state.caseForms.caseForms,
      companyCaseForms: state => state.companyCaseForms.companyCaseForms,
      groupCaseForms: state => state.groupCaseForms.groupCaseForms,
      actualTabCaseForms: state => state.caseForms.actualTabCaseForms,
      lavviraCaseForms: state => state.caseForms.lavviraCaseForms,
      actualSuggestionsEditorStorage: state => state.editorStore.actualSuggestionsEditorStorage
    }),
    actualCaseViewRoute () {
      let route
      if (this.currentWorkbench) {
        route = `/models/${this.$route.params._id}/${this.currentWorkbench._id}`
      }
      return route
    },
    currentCaseForm () {
      return this.caseForms.find(c => c._id === this.$route.params._id) || this.companyCaseForms.find(c => c._id === this.$route.params._id) ||
      this.groupCaseForms.find(c => c._id === this.$route.params._id) || this.lavviraCaseForms.find(c => c._id === this.$route.params._id) || []
    },
    currentWorkbench () {
      return this.currentCaseForm.workbenches.find(w => w._id === this.$route.params.workbenchId)
    },
    currentRequiredCaseModelDocs () {
      return this.currentCaseForm.documents
    },
    allCurrentTemplateData () {
      return this.currentCaseForm.workbenches.reduce((acc, w) => {
        acc.push(
          ...w.templates
        )
        return acc
      }, [])
    },
    actualTemplate () {
      let actualTemps = []
      if (this.$route.params.templateId) {
        for (let i = 0; i < this.currentCaseForm.workbenches.length; i++) {
          const wb = this.currentCaseForm.workbenches[i]
          const actualTemp = wb.templates.find(t => t._id === this.$route.params.templateId)
          if (actualTemp) {
            actualTemps.push(actualTemp)
          }
        }
      }
      return actualTemps[0]
    },
    actualTemplateAndQuestionairreSuggestions () {
      let suggestions = []
      let finalFilter = []
      if (this.actualSuggestionsEditorStorage.length) {
        this.actualSuggestionsEditorStorage.forEach(element => {
          suggestions.push(element.attrs)
        })
      }
      if (this.actualTemplate && this.actualTemplate.questionnaire.fields && this.actualTemplate.questionnaire.fields.length) {
        this.actualTemplate.questionnaire.fields.forEach(field => {
          if (field.fieldType !== 'file-input') {
            suggestions.push(field)
          }
        })
      }
      if (suggestions.length) {
        finalFilter = uniqBy(suggestions, 'id')
      }
      return finalFilter
    },
    requestedDocumentsInQuestionairre () {
      let requestedDocs = []
      if (this.actualTemplate && this.actualTemplate.questionnaire.fields) {
        this.actualTemplate.questionnaire.fields.forEach(field => {
          if (field.fieldType === 'file-input') {
            requestedDocs.push(field)
          }
        })
      }
      return requestedDocs
    },
    sharedWithMembers () {
      const arr = []
      if (this.currentCaseForm.sharedWith.accounts.length) {
        this.currentCaseForm.sharedWith.accounts.forEach((acc) => {
          const participant = {
            _id: acc._id,
            participantName: acc.accountData.firstName && acc.accountData.lastName ? acc.accountData.firstName + ' ' + acc.accountData.lastName : acc.accountName,
            avatar: acc.avatar,
            type: 'account'
          }
          arr.push(participant)
        })
      }
      if (this.currentCaseForm.sharedWith.company.length) {
        this.currentCaseForm.sharedWith.company.forEach((c) => {
          const participant = {
            participantName: c.companyData.companyName,
            avatar: null,
            type: 'company'
          }
          arr.push(participant)
        })
      }
      if (this.currentCaseForm.sharedWith.groups.length) {
        this.currentCaseForm.sharedWith.groups.forEach((g) => {
          const participant = {
            participantName: g.groupName,
            avatar: null,
            type: 'group'
          }
          arr.push(participant)
        })
      }
      return arr
    },
    disabledRule () {
      let rule
      if (this.account.companyId) rule = false
      else rule = true
      return rule
    }
  },
  watch: {
    caseForms: {
      handler (value) {
        const caseFormIndex = this.caseForms.findIndex(cf => cf._id === this.$route.params._id)
        this.caseForm = this.caseForms[caseFormIndex]
      },
      immediate: true
    },
    documentSuggestionsDialog (value) {
      if (value === false) {
        this.resetDocumentSuggestionsDialog = true
      } else {
        this.resetDocumentSuggestionsDialog = false
      }
    },
    $route (to, from) {
      if (this.actualCaseViewRoute && to.fullPath === this.actualCaseViewRoute) {
        this.openLeftDrawerMenu()
      }
      if (this.$route.params.templateId) {
        this.closeLeftDrawerMenu()
      }
    }
  },
  updated () {
    if (this.$route.params.templateId) {
      this.closeLeftDrawerMenu()
    }
  },
  async created () {
    const caseFormIndex = this.caseForms.findIndex(cf => cf._id === this.$route.params._id)
    if (caseFormIndex === -1) {
      try {
        const { data } = await this.$axios.get(`/getCaseForm/${this.$route.params._id}`)
        this.caseForm = data.data
        if (!this.$route.params.workbenchId && this.caseForm && this.caseForm.workbenches && this.caseForm.workbenches[0]) {
          this.$router.push(`/models/${this.$route.params._id}/${this.caseForm.workbenches[0]._id}`)
        }
      } catch (e) {
        console.error(e, 'error')
      }
    } else {
      this.caseForm = this.caseForms[caseFormIndex]
      if (!this.$route.params.workbenchId && this.caseForm && this.caseForm.workbenches && this.caseForm.workbenches[0]) {
        this.$router.push(`/models/${this.$route.params._id}/${this.caseForm.workbenches[0]._id}`)
      }
    }
    EventBus.$on('update-company-case-model', this.onUpdatedCaseModel)
    EventBus.$on('suggestion-is-selected', this.selectSuggestion)
    EventBus.$on('suggestion-to-delete', this.deleteReqiredDocumentSuggestion)
    EventBus.$on('actual-tab-template-view-case-models', this.onActualTabTemplateViewCaseModels)
    EventBus.$on('refresh-case-models', this.onRefreshCaseModels)
  },
  beforeDestroy () {
    EventBus.$off('update-company-case-model', this.onUpdatedCaseModel)
    EventBus.$off('suggestion-is-selected', this.selectSuggestion)
    EventBus.$off('suggestion-to-delete', this.deleteReqiredDocumentSuggestion)
    EventBus.$off('actual-tab-template-view-case-models', this.onActualTabTemplateViewCaseModels)
    EventBus.$off('refresh-case-models', this.onRefreshCaseModels)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateCaseForm: 'caseForms/updateCaseForm',
      addCaseRequiredDocuments: 'caseForms/addCaseRequiredDocuments',
      addCaseFormWorkbench: 'caseForms/addCaseFormWorkbench',
      addCaseFormWorkbenchTemplate: 'caseForms/addCaseFormWorkbenchTemplate',
      addCaseFormWorkbenchRequiredDocument: 'caseForms/addCaseFormWorkbenchRequiredDocument',
      deleteCaseModelWbRequiredDocument: 'caseForms/deleteCaseModelWbRequiredDocument',
      deleteRequiredDocFromCaseModel: 'caseForms/deleteRequiredDocFromCaseModel'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    onRefreshCaseModels () {
      this.componentKey++
    },
    openLeftDrawerMenu () {
      this.toggleDrawer(true)
    },
    closeLeftDrawerMenu () {
      this.toggleDrawer(false)
    },
    onActualTabTemplateViewCaseModels (tab) {
      this.actualTabTemplateViewCaseModel = tab
    },
    triggerAction (actionType) {
      if (actionType === 'addWorkbench') {
        this.addWorkbench()
      }
      if (actionType === 'editWorkbenchesDialog') {
        this.openEditDialog()
      }
    },
    translateCategoryIfNeeded (category) {
      if (category === 'none') return this.$t('common|not_available')
      else return category
    },
    fieldFocus (focusState) {
      this.focus = focusState
      this.descriptionFields.push(this.currentCaseForm.name)
    },
    async changeCaseModelName () {
      const payload = {
        name: this.currentCaseForm.name
      }
      try {
        await this.updateCaseForm({ _id: this.currentCaseForm._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      }
    },
    async changeCaseModelDescription () {
      this.focus = null
      this.descriptionFields = []
      const element = document.getElementById(`modelDescription_${this.currentCaseForm._id}`)
      const res = element.textContent.trimStart()
      const payload = {
        description: res
      }
      try {
        await this.updateCaseForm({ _id: this.currentCaseForm._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      }
    },
    async pinToDashboard (caseForm) {
      const payload = {
        pinned: this.account._id
      }
      try {
        await this.updateCaseForm({
          _id: caseForm._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_model_pinned'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_model_pin_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async removePinFromDashboard (caseForm) {
      const payload = {
        removePinId: this.account._id
      }
      try {
        await this.updateCaseForm({
          _id: caseForm._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_model_removed_from_dashboard'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_model_removal_from_dashboard_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async addWorkbench () {
      const res = await this.$dialog.prompt({
        title: this.$t('mixed|workbench_name'),
        text: this.$t('actions|enter_workbench_name'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [{
          text: this.$t('actions|submit'), color: 'primary'
        }]
      })
      if (!res) return
      try {
        const payload = {
          name: res
        }
        await this.addCaseFormWorkbench({ _id: this.currentCaseForm._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    onQuestionnaire (template) {
      EventBus.$emit('dialog-view-questionairre', {
        _id: this.currentCaseForm._id,
        workbenchId: this.currentWorkbench._id,
        templateId: template._id,
        questionnaire: template.questionnaire
      })
    },
    async selectSuggestion (suggestion) {
      if (!suggestion) return
      try {
        const payload = {
          suggestion
        }
        await this.addCaseFormWorkbenchRequiredDocument({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    async deleteRequiredDoc (doc) {
      // delete document from case model
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('warning|delete_document_from_list')
      })
      if (res) {
        const payload = doc
        try {
          await this.deleteRequiredDocFromCaseModel({ _id: this.currentCaseForm._id, payload })
        } catch (error) {
          console.error(this.error, 'error')
        }
      }
    },
    async deleteReqiredDocumentSuggestion (s) {
      // delete document from workbench
      this.loading = true
      const payload = {
        requiredDocumentsToDelete: s
      }
      try {
        await this.deleteCaseModelWbRequiredDocument({ _id: this.currentCaseForm._id, workbenchId: this.currentWorkbench._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    changeCategory (item) {
      EventBus.$emit('open-change-case-model-category-dialog', { item })
    },
    openEditDialog (event) {
      this.$refs.EditCaseModelWorkbenches.dialog = true
    },
    // openGeneralQuestionnaire (event) {
    //   this.generalQDialog = true
    // },
    // tabChanged (payload) {
    //   payload.tab.$children[0].resetTemplate()
    //   this.selectedWorkbench = payload.tab.$children[0].workbench._id
    // },
    onUpdatedCaseModel (updatedCaseModel) {
      this.caseForm = updatedCaseModel
    },
    inUse (suggestion) {
      const inUse = this.actualTemplate.questionnaire.fields.find(f => f.id === suggestion.id)
      if (inUse) {
        return true
      } else {
        return false
      }
    },
    scrollToSuggestion (suggestion) {
      const element = document.getElementById(suggestion.id)
      if (element && element.className === 'mention') {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
        element.style.backgroundColor = 'red'
        setTimeout(() => {
          element.style.backgroundColor = ''
        }, '1000')
      } else if (element && element.className !== 'mention') {
        this.addToast({
          title: this.$t('error|suggestion_not_in_use'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    inUseInQuestionnaire (suggestion) {
      const inUse = this.actualTemplate.questionnaire.fields.find(f => f.fieldKey.toLowerCase() === suggestion.attrs.fieldKey.toLowerCase())
      if (inUse) {
        return true
      } else {
        return false
      }
    },
    scrollToQuestionnaireField (suggestion) {
      if (suggestion && suggestion.fieldType && suggestion.fieldType === 'file-input') {
        let transformedSuggestion = {}
        transformedSuggestion.attrs = suggestion
        const usedInQuestionnaire = this.inUseInQuestionnaire(transformedSuggestion)
        const scrollToElement = {
          id: suggestion.id,
          inUse: usedInQuestionnaire,
          tab: 1 // questionnaire
        }
        EventBus.$emit('scroll-to-field-in-questionnaire', scrollToElement)
      }
    },
    useCaseForm (caseForm) {
      EventBus.$emit('dialog-add-case-from-case-model', caseForm)
    },
    async shareCaseForm (caseForm) {
      EventBus.$emit('dialog-share-case-model', caseForm, this.actualTabCaseForms)
    },
    async exportCaseForm () {
      this.loading = true
      await this.createZip()
      this.loading = false
    },
    async downloadDocument (path) {
      const { data } = await this.$axios({
        url: path,
        method: 'GET',
        responseType: 'blob' // important
      })
      return data
    },
    async createZip () {
      let zipNameInput = this.currentCaseForm.name
      let zip = new JSZip()

      for (let index = 0; index < this.currentCaseForm.workbenches.length; index++) {
        const wb = this.currentCaseForm.workbenches[index]
        let workbenchFolder = zip.folder(`${wb.name}`)
        for (let i = 0; i < wb.templates.length; i++) {
          const template = wb.templates[i]
          const json = template.templateData.json
          this.$refs.Editor.editor.commands.setContent(json)
          const doc = this.$refs.Editor.editor.view.state.doc
          const type = 'docx'
          const templateName = template.name + '.docx'
          const caseExport = true
          const exported = await exportTemplate(doc, templateName, type, caseExport)
          workbenchFolder.file(template.name, exported)
        }
      }
      this.$refs.Editor.editor.destroy()
      zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, zipNameInput)
        }).catch((err) => {
          console.error('createZip', err)
        })
    },
    async moveInList (evt) {
      try {
        const payload = {
          documents: [evt.moved.element],
          move: !!evt.moved,
          oldIndex: evt.moved ? evt.moved.oldIndex : null,
          newIndex: evt.moved ? evt.moved.newIndex : null
        }
        await this.addCaseRequiredDocuments({ _id: this.currentCaseForm._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    onDragStart () {
      EventBus.$emit('drag-area-active-case-model')
    },
    async onDragEnd () {
      EventBus.$emit('drag-area-not-active-case-model')
    }
  }
}
</script>

<style scoped>
  .tabs-text {
    text-transform:none !important;
  }
  .input:focus {
    outline: none !important;
    border:1px solid #234e79;
  }
  .handle-icon {
    position: absolute;
    left: 0px;
    cursor: move;
  }
  .centered-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
