import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      caseForms: state => state.caseForms.caseForms,
      companyCaseForms: state => state.companyCaseForms.companyCaseForms,
      groupCaseForms: state => state.groupCaseForms.groupCaseForms,
      templates: state => state.templates.templates
    }),
    currentCaseForm () {
      return this.caseForms.find(c => c._id === this.$route.params._id) || this.companyCaseForms.find(c => c._id === this.$route.params._id) ||
        this.groupCaseForms.find(c => c._id === this.$route.params._id)
    },
    workbench () {
      return this.currentCaseForm ? this.currentCaseForm.workbenches.find(w => w._id === this.$route.params.workbenchId) : null
    },
    template () {
      return this.workbench ? this.workbench.templates.find(t => t._id === this.$route.params.templateId) : null
    }
  },
  methods: {
    ...mapActions({
      updateCaseFormWorkbenchTemplate: 'caseForms/updateCaseFormWorkbenchTemplate',
      updateQuestionnaireInCaseModel: 'questionnaire/updateQuestionnaireInCaseModel',
      uploadDocument: 'documents/uploadDocument',
      addToast: 'toasts/addToast'
    }),
    async updateActualTemplateName (template, type) {
      let res
      if (type && type === 'from editor') {
        res = template.name
      } else {
        res = await this.$dialog.prompt({
          title: this.$t('actions|change_template_name'),
          text: this.$t('actions|enter_new_name'),
          textField: {
            // Any addtional props/attrs that will be binded to v-text-field component
            type: 'text',
            outlined: true,
            dense: true,
            class: 'mt-6'
          },
          actions: [
            {
              text: this.$t('actions|submit'), color: 'primary'
            }
          ]
        })
      }
      if (!res) return
      const payload = {
        name: res
      }
      try {
        await this.updateCaseFormWorkbenchTemplate({
          _id: this.currentCaseForm._id,
          workbenchId: this.workbench._id,
          templateId: template._id,
          payload
        })
        this.addToast({
          title: 'Case Model Template Updated!',
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: 'Case Model Template update FAILED!',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async addNewQuestionnaireFieldInCaseModel (newField) {
      try {
        const payload = {
          field: newField,
          addNewField: true
        }
        const _id = this.template.questionnaire._id
        const caseModelId = this.currentCaseForm._id
        const workbenchId = this.workbench._id
        const templateId = this.template._id
        await this.updateQuestionnaireInCaseModel({ _id, caseModelId, workbenchId, templateId, payload })
        const element = document.getElementById(newField.id)
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: 'Questionnaire update FAILED!',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async addNewFieldsInCaseModelQuestionnaire (newFields) {
      try {
        const payload = {
          fields: newFields,
          addNewMultipleFields: true
        }
        const _id = this.template.questionnaire._id
        const caseModelId = this.currentCaseForm._id
        const workbenchId = this.workbench._id
        const templateId = this.template._id
        await this.updateQuestionnaireInCaseModel({ _id, caseModelId, workbenchId, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: 'Questionnaire update FAILED!',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async updateQuestionnaireFieldsOrder (actualFields) {
      try {
        const payload = {
          fields: actualFields,
          newFieldsOrder: true
        }
        const _id = this.template.questionnaire._id
        const caseModelId = this.currentCaseForm._id
        const workbenchId = this.workbench._id
        const templateId = this.template._id
        await this.updateQuestionnaireInCaseModel({ _id, caseModelId, workbenchId, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: 'Questionnaire update FAILED!',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async updateCaseModelQuestionnaireField (field) {
      try {
        const payload = {
          field: field,
          updateFieldContent: true
        }
        const _id = this.template.questionnaire._id
        const caseModelId = this.currentCaseForm._id
        const workbenchId = this.workbench._id
        const templateId = this.template._id
        await this.updateQuestionnaireInCaseModel({ _id, caseModelId, workbenchId, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: 'Questionnaire update FAILED!',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async deleteFieldInCaseModelQuestionnaire (field) {
      try {
        const payload = {
          fieldToBeDeleted: field.fieldToBeDeleted,
          deleteFieldFromQuestionnaire: true
        }
        const _id = this.template.questionnaire._id
        const caseModelId = this.currentCaseForm._id
        const workbenchId = this.workbench._id
        const templateId = this.template._id
        await this.updateQuestionnaireInCaseModel({ _id, caseModelId, workbenchId, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: 'Questionnaire update FAILED!',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async saveTemplate ({ _id = this.template._id, payload }) {
      try {
        await this.updateCaseFormWorkbenchTemplate({
          _id: this.currentCaseForm._id,
          workbenchId: this.workbench._id,
          templateId: _id,
          payload
        })
        this.addToast({
          title: 'Case Models Template Updated!',
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: 'Case Models Template update FAILED!',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async updateQuestionnaire (questionairre) {
      await this.$axios.post(`/updateQuestionnaire/${questionairre._id}`, {
        fields: questionairre.fields
      })
    }
  }
}
